import { Fragment } from 'react';

import './ArchiveSingle.css';

const SingleInfoTable = ({ data }) => {

    const { genres, productionCompanies, revenue, budget, productionCountry, homepage, created_by } = data;

    return (
        <Fragment>
            <h3>INFO</h3>
            <div className="info-table">    
                {genres && genres.length > 0 &&
                    <div>
                        <h3>Genere</h3>
                        <span className="detail">{genres.join(', ')}</span>
                    </div>
                }
                {created_by && created_by.length > 0 && 
                    <div className="created-by">
                        <h3>Creata da</h3>
                        <div>
                            {created_by.map((creator) => 
                                <span key={creator.id} className="detail">{creator.name}</span>
                            )}
                        </div>
                    </div>
                }
                {productionCompanies && productionCompanies.length > 0 &&
                    <div>
                        <h3>Produzione</h3>
                        <span className="detail">{productionCompanies.join(', ')}</span>
                    </div>
                }  
                {productionCountry && productionCountry.length > 0 &&
                    <div>
                        <h3>Paese</h3>
                        <span className="detail">{productionCountry.join(', ')}</span>
                    </div>
                }  
                {revenue > 0 &&
                    <div>
                        <h3>Incasso</h3>
                        <span className="detail">
                            {revenue.toLocaleString('it-IT')+' $'}
                        </span>
                    </div>
                } 
                {budget > 0 &&
                    <div>
                        <h3>Budget</h3>
                        <span className="detail">
                            {budget.toLocaleString('it-IT')+' $'}
                        </span>
                    </div>
                } 
                {homepage &&
                    <div>
                        <a href={homepage} target="_blank" rel="noreferrer">
                            Maggiori informazioni
                        </a>
                    </div>
                }   
            </div>
        </Fragment>
    );
}

export default SingleInfoTable;