import { DISCOVER_MOVIES_BASEURL } from "../../api/api-settings";

import MainArchive from "../../components/MainArchive/MainArchive";

const LatestMovies = () => {
    
    const queryParams = '&release_date.lte='+(new Date()).toISOString()+'&with_release_type=3|2&sort_by=release_date.desc';
   
    return <MainArchive 
        displaysShows={false} 
        screenTitle="ULTIME USCITE" 
        baseUrl={DISCOVER_MOVIES_BASEURL}
        queryParams={queryParams}/>
};

export default LatestMovies;