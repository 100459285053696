import { NavLink } from "react-router-dom";
import classes from '../MainHeader.module.css';

import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const Submenu = ({index, title, links, active,  clicked}) => {
    
    const submenuClasses = active === index ? classes.submenu +' '+classes['active'] : classes.submenu;

    return (
        <li onClick={() => clicked(index)}>
            <span>
                {title}
                {active === index ? <AiOutlineMinus /> : <AiOutlinePlus/>}
            </span>    
            <ul className={submenuClasses}>
                {links.map((link, index)=>{
                    return (
                        <li key={index}>
                            <NavLink to={link.path} className={(navData) => navData.isActive ? classes['active-link'] : null}>
                                {link.title}
                            </NavLink>
                        </li>  
                    )
                })}
            </ul>
        </li>
    );
};

export default Submenu;