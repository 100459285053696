import React, { Component, Fragment } from 'react';
import { fetchTMDB } from '../../api/fetch-tmdb';

import Loader from '../Layout/Loader';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import ActorCard from '../ActorCard/ActorCard';

import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

import './ArchiveSingle.css';

class Cast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            cast: [],
            error: '',
            hideControls: false
        };
        this.sliderSettings = {
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                }
            ]
        }
        this.carouselPrev = this.carouselPrev.bind(this);
        this.carouselNext = this.carouselNext.bind(this);
    }   

    fetchCast = async (reqUrl) => {
        const response = await fetchTMDB(reqUrl);
        if(response.data) {
            this.setState({isLoading: false});
            let cast = [];
            if(response.data.cast.length > 0) {
                cast = response.data.cast.filter((c) => {
                   return c.known_for_department === 'Acting';
                });
            }
            this.setState({cast: cast});  
            if(this.slider) {
                this.slider.slickGoTo(0, true);  
            }
        }
        if(response.error) {
            this.setState({error: 'Non è stato possibile recuperare il cast', isLoading: false});   
        }
    }

    componentDidMount() {
        this.fetchCast(this.props.reqUrl);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.reqUrl !== this.props.reqUrl) {
            this.fetchCast(this.props.reqUrl);
        }
    }

    carouselPrev () {
        this.slider.slickPrev();
    }

    carouselNext () {
        this.slider.slickNext();
    }

    render () {
        return (
            <div className={`cast ${this.state.isLoading ? 'loading' : ''}`}>
                {this.state.isLoading && <Loader />}
                {this.state.cast && this.state.cast.length > 0 && !this.state.isLoading &&
                    <Fragment>
                        <h3>Cast</h3>
                        <Slider {...this.sliderSettings} ref={c => (this.slider = c)}>
                            {this.state.cast.map((c)=>{
                                return (
                                    <div key={c.id}>
                                        <ActorCard name={c.name} character={c.character} profilePic={c.profile_path} />
                                    </div>
                                )
                            })}
                        </Slider>
                        <div className="slider-custom-controls">
                            <button className="custom-slider-button" onClick={this.carouselPrev}>
                                <AiOutlineLeft />
                            </button>
                            <button className="custom-slider-button" onClick={this.carouselNext}>
                                <AiOutlineRight />
                            </button>
                        </div>
                    </Fragment>
                }
                {this.state.error && !this.state.isLoading && <p>{this.state.error}</p> }
            </div>
        )
    }
}

export default Cast;