import { GET_NOW_PLAYING_MOVIES_HP, GET_NOW_PLAYING_SHOWS_HP, GET_POPULAR_MOVIES_HP, GET_POPULAR_SHOWS_HP } from '../../api/api-settings';

import { fetchTMDB } from '../../api/fetch-tmdb';

import * as actionTypes from './actionTypes';

export const fetchNowPlayngMovies = () => {
    return async (dispatch) => {
        const response = await fetchTMDB(GET_NOW_PLAYING_MOVIES_HP);
        if(response.data.results) {
            dispatch({type: actionTypes.FETCH_NOW_PLAYING_MOVIES, payload: response.data.results});
            return Promise.resolve();
        } else {
            console.log(response.error);
        }
    };
};

export const fetchNowPlayngShows = () => {
    return async (dispatch) => {
        const response = await fetchTMDB(GET_NOW_PLAYING_SHOWS_HP);
        if(response.data.results) {
            dispatch({type: actionTypes.FETCH_NOW_PLAYING_SHOWS, payload: response.data.results});
            return Promise.resolve();
        } else {
            console.log(response.error);
        }
    };
};

export const fetchPopularMovies = () => {
    return async (dispatch) => {
        const response = await fetchTMDB(GET_POPULAR_MOVIES_HP);
        if(response.data.results) {
            dispatch({type: actionTypes.FETCH_POPULAR_MOVIES, payload: response.data.results});
            return Promise.resolve();
        } else {
            console.log(response.error);
        }
    };
};

export const fetchPopularShows = () => {
    return async (dispatch) => {
        const response = await fetchTMDB(GET_POPULAR_SHOWS_HP);
        if(response.data.results) {
            dispatch({type: actionTypes.FETCH_POPULAR_SHOWS, payload: response.data.results});
            return Promise.resolve();
        } else {
            console.log(response.error);
        }
    };
};

