import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { 
    API_KEY, 
    GET_MOVIE_DETAILS_BASEURL, 
    GET_MOVIE_PROVIDERS_BASEURL, 
    GET_MOVIE_TRAILER_BASEURL, 
    GET_MOVIE_CREDITS_BASEURL,
    GET_SIMILAR_MOVIES_BASEURL,
    IMAGE_BASEURL, 
    LANGUAGE, 
    POSTER_BASEURL } from '../../api/api-settings';

import MainContainer from '../../components/Layout/MainContainer';
import scoreCalc from '../../utility/scoreCalc';
import dateFormatted from '../../utility/dateFormatter';

import '../../components/ArchiveSingle/ArchiveSingle.css';

import SingleInfoTable from '../../components/ArchiveSingle/SingleInfoTable';
import Providers from '../../components/ArchiveSingle/Providers';
import Video from '../../components/ArchiveSingle/Video';
import Cast from '../../components/ArchiveSingle/Cast';
import Related from '../../components/ArchiveSingle/Related';


const Movie = () => {
    
    const {id} = useParams();
    
    const [details, setDetails] = useState();
    const [error, setError] = useState('');

    const providersReqUrl =  GET_MOVIE_PROVIDERS_BASEURL(id);
    const videoReqUrl = GET_MOVIE_TRAILER_BASEURL(id);
    const castReqUrl = GET_MOVIE_CREDITS_BASEURL(id);
    const relatedReqUrl = GET_SIMILAR_MOVIES_BASEURL(id);

    useEffect(() =>{
        
        const fetchDetails = async () => {
            try {
                const response = await fetch(GET_MOVIE_DETAILS_BASEURL+id+API_KEY+LANGUAGE);
                if(!response.ok) {
                   throw new Error('Non è stato possibile recuperare le informazioni riguardanti il film'); 
                }
                const data = await response.json();
                setDetails(data);
                console.log(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchDetails();       
    }, [id]);
    
    let score, genres = [], productionCompanies, productionCountry, movieInfo, releaseDateFormatted;    
    
    if(details) {

        score = scoreCalc(details.vote_average);
        
        genres = details.genres.map(gn => gn.name);
        
        productionCountry = details.production_countries.map(pc => pc.iso_3166_1);

        productionCompanies = details.production_companies.map(pc => pc.name);


        if(details.release_date) {
            releaseDateFormatted = dateFormatted(details.release_date);
        }

        movieInfo = (
            <Fragment>
                <MainContainer>
                    {details.backdrop_path &&
                        <div className="archive-single__bg" style={{backgroundImage: 'url(' + IMAGE_BASEURL+details.backdrop_path + ')'}}></div>
                    }
                    <div className="archive-single__row">
                        <div className="archive-single__one-third-col archive-single__poster-col">
                            <div className="archive-single__poster" style={{backgroundImage: 'url(' + POSTER_BASEURL+details.poster_path + ')'}}></div> 
                        </div>
                        <div className="archive-single__two-third-col archive-single__details-col">
                            <h1>{details.title}</h1>
                            <div className="archive-single__score">
                                {score}
                            </div>
                            {releaseDateFormatted && 
                                <Fragment>
                                <h3>DATA DI USCITA</h3>
                                <span className="detail">{releaseDateFormatted}</span> 
                                </Fragment>
                            }
                            {details.runtime > 0 && 
                                <Fragment>
                                <h3>DURATA</h3>
                                <span className="detail">{details.runtime}'</span> 
                                </Fragment>
                            }
                            {(details.overview || details.overview) && 
                                <Fragment>
                                    <h3>DESCRIZIONE</h3>
                                    {details.tagline && <p className="description">{details.tagline}</p>}
                                    {details.overview && <p className="description">{details.overview}</p>}
                                </Fragment>
                            }
                        </div>
                    </div>
                    <div className="archive-single__row">
                        <div className="archive-single__one-third-col order-2-mobile">
                            <SingleInfoTable data={{
                                genres, 
                                productionCountry, 
                                productionCompanies,
                                revenue: details.revenue,
                                budget: details.budget, 
                                homepage: details.homepage
                            }} />
                            <Providers reqUrl={providersReqUrl} />    
                        </div>
                        <div className="archive-single__two-third-col order-1-mobile">
                            <Video reqUrl={videoReqUrl} />
                            <Cast reqUrl={castReqUrl} />
                            <Related title={details.title} reqUrl={relatedReqUrl} />
                        </div>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }

    
    return (
        <Fragment>
            <div className="archive-single">
                <div className="archive-single-wrapper">
                    {!error && details && movieInfo}
                    {error && <MainContainer><p>{error}</p></MainContainer>}
                </div>
            </div>
        </Fragment>   
    );
};

export default Movie;