import MainArchive from "../MainArchive/MainArchive";

const SearchTab = ({ title, searchUrl, displaysShows }) => {
    return (
        <MainArchive
            displayFilter={false}
            displaysShows={displaysShows} 
            screenTitle={title} 
            baseUrl={searchUrl}/>
    );
}

export default SearchTab;