import SearchBar from "../../Search/SearchBar";
import MainContainer from "../../Layout/MainContainer";

const Head = () => {
    return (
        <div className="hp-head">
            <MainContainer>
                <div className="hp-head__content">
                    <h2>Il cinema ora ha una nuova casa <span>Scopri i nuovi film e le nuove serie tv in onda!</span></h2>
                    <SearchBar />
                </div>
            </MainContainer>
        </div>
    );
}

export default Head;