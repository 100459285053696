import { BsStarFill, BsStarHalf } from "react-icons/bs";

const scoreCalc = (vote) => {
    let starsNumber = (vote*5)/10;
    let score = [];
    
    if(starsNumber === 0) {
        score = 'N/A';
    } else {
        let i = 1;
        while(i<=starsNumber) {
            score.push(<BsStarFill key={i}/>);
            i++;
        }
        if(starsNumber - (i-1) > 0.5) {
            score.push(<BsStarHalf key={i+1}/>)
        }
    }

    return score;
};

export default scoreCalc;