import React, { Component } from 'react';

import { fetchTMDB } from '../../api/fetch-tmdb';

import Loader from '../Layout/Loader';
import MainContainer from '../Layout/MainContainer';
import ArchiveFilter from './ArchiveFilter/ArchiveFilter';
import ArchiveList from './ArchiveList/ArchiveList';
import SearchBar from '../Search/SearchBar';

import "./MainArchive.css";


class MainArchive extends Component {

    constructor(props){
        super(props);
        this.loadMoreRef = React.createRef();
        this.loadMoreScrollHandler = this.loadMoreScrollHandler.bind(this);
        this.queryParams = new URLSearchParams(this.props.queryParams);
        this.state = {
            items: [],
            isLoading: false,
            isLoadingMore: false,
            page: 1,
            totalPages: 1,
            error: '',
        }
    }

    componentDidMount() {
        this.fetchFirstPage();
        window.addEventListener('scroll', this.loadMoreScrollHandler);   
    }

    componentDidUpdate(prevProps) {
        if(prevProps.baseUrl !== this.props.baseUrl) {
            this.fetchFirstPage();
        }
    }

    fetchFirstPage() {
        (async()=>{
            this.setState({isLoading: true});
            const response = await fetchTMDB(this.props.baseUrl, '&'+this.queryParams.toString());
            if(response.data) {
                this.setState({
                    items: response.data.results, 
                    page: response.data.page, 
                    totalPages: response.data.total_pages, 
                    isLoading: false
                });    
            }
            if(response.error) {
                this.setState({
                    error: response.error,
                    isLoading: false
                });
            }
        })();
    }

    updateFilterQueryString(filterData) {
        if(filterData.has('genres')) {
           this.queryParams.set('with_genres', filterData.getAll('genres').toString());
        } else {
            this.queryParams.delete('with_genres');
        }

        if(filterData.has('release-date-max')) {
            if(this.props.displaysShows) {
                this.queryParams.set('first_air_date.lte', filterData.get('release-date-max'));
            } else {
                this.queryParams.set('release_date.lte', filterData.get('release-date-max'));
            }
        }
        if(filterData.has('release-date-min')) {
            if(this.props.displaysShows) {
                this.queryParams.set('first_air_date.gte', filterData.get('release-date-min'));
            } else {
                this.queryParams.set('release_date.gte', filterData.get('release-date-min'));
            }
        }

        if(filterData.has('rating')) {
            this.queryParams.set('vote_average.gte', filterData.get('rating'));
        }
        this.fetchFirstPage();
    }
    
    loadMoreScrollHandler () {
        if(this.loadMoreRef.current && this.state.page <= this.state.totalPages && !this.state.isLoadingMore && !this.state.isLoading) {
            if(window.pageYOffset+window.innerHeight >= this.loadMoreRef.current.offsetTop) {
                this.loadMore(this.props.queryParams);
            }
        }
    }

    loadMore () {
        (async()=>{
            this.setState({isLoadingMore: true});
            const response = await fetchTMDB(this.props.baseUrl, '&'+this.queryParams.toString()+'&page='+(this.state.page+1));
            if(response.data) {
                this.setState((prevState)=>{
                    return {
                        items: [...prevState.items, ...response.data.results], 
                        page: response.data.page, 
                        totalPages: response.data.total_pages, 
                        isLoadingMore: false
                    }
                });     
            }
            if(response.error) {
                this.setState({
                    error: response.error,
                    isLoadingMore: false
                });
            }
        })();
    }

    render() {

        const archiveClassName = !this.props.displaysShows ? 'archive' : 'archive tv-shows';
        const titleTag = !this.props.displaysShows ? 'FILM' : 'SERIE TV';
        
        const loadMore = (
            <div className="load-more" ref={this.loadMoreRef}>
                <span></span>    
            </div>
        );

        const displayLoadMore = !this.state.isLoading && !this.state.error !== '' && this.state.page < this.state.totalPages;

        return (
            <div className={archiveClassName}>
                <MainContainer>
                    <span className="title-tag">{titleTag}</span>
                    <h1>{this.props.screenTitle}</h1>
                    <div className="archive__content">
                        <div className="archive__sidebar">
                            <div className="archive__sidebar-searchbar-wrapper">
                                <h3>SEARCH</h3>
                                <SearchBar displaysShows={this.props.displaysShows}/>
                            </div>
                            {
                                this.props.displayFilter &&
                                <ArchiveFilter displaysShows={this.props.displaysShows} onFilterSubmit={(filterData) => this.updateFilterQueryString.call(this, filterData)} />
                            }
                        </div>
                        <div className="archive__list-wrapper">
                            {!this.state.isLoading && !this.state.error && this.state.items.length >= 1 && 
                                <ArchiveList displaysShows={this.props.displaysShows} items={this.state.items} />}
                            {!this.state.isLoading && !this.state.error && this.state.items.length === 0 && <p>Nessun risultato trovato</p>}
                            {this.state.isLoading && !this.state.error && <Loader />}
                            {!this.state.isLoading && this.state.error && <p>{this.state.error}</p>}
                            {displayLoadMore && loadMore}
                        </div>
                    </div>
                </MainContainer>
            </div>  
        );
    }
}

MainArchive.defaultProps = {
    queryParams: "&=",
    displaysShows: false,
    displayFilter: true
};

export default MainArchive;