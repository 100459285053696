import { DISCOVER_SHOWS_BASEURL, SHOWS_WATCH_REGION, SHOWS_WATCH_PROVIDERS } from "../../api/api-settings";

import MainArchive from "../../components/MainArchive/MainArchive";

const TopRated = () => {
    
    const queryParams = SHOWS_WATCH_REGION+SHOWS_WATCH_PROVIDERS+'&vote_average.gte=8&vote_count.gte=2000&sort_by=vote_average.desc';

    return <MainArchive 
        displaysShows={true} 
        screenTitle="TOP RATED" 
        baseUrl={DISCOVER_SHOWS_BASEURL}
        queryParams={queryParams}/>
};

export default TopRated;