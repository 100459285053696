import { DISCOVER_SHOWS_BASEURL, SHOWS_WATCH_REGION, SHOWS_WATCH_PROVIDERS } from "../../api/api-settings";

import MainArchive from "../../components/MainArchive/MainArchive";

const Popular = () => {
    
    const queryParams = SHOWS_WATCH_REGION+SHOWS_WATCH_PROVIDERS+'&sort_by=popularity.desc';

    return <MainArchive 
        displaysShows={true} 
        screenTitle="POPOLARI" 
        baseUrl={DISCOVER_SHOWS_BASEURL}
        queryParams={queryParams}/>
};

export default Popular;