
const OffCanvasWrapper = ({children, displayNav, handleNavDisplay}) => {
    return (
        <div className={displayNav ? "off-canvas-wrapper nav-displayed" : "off-canvas-wrapper"}>
            <div className="off-canvas-wrapper__backdrop" onClick={handleNavDisplay}></div>
            {children}
        </div>        
    );
}

export default OffCanvasWrapper;