import { DISCOVER_MOVIES_BASEURL } from "../../api/api-settings";

import MainArchive from "../../components/MainArchive/MainArchive";

const TopRated = () => {
    
    const queryParams = '&with_release_type=3|2&vote_average.gte=8&vote_count.gte=2000&sort_by=vote_average.desc';

    return <MainArchive 
        displaysShows={false} 
        screenTitle="TOP RATED" 
        baseUrl={DISCOVER_MOVIES_BASEURL}
        queryParams={queryParams}/>
};

export default TopRated;