import { DISCOVER_SHOWS_BASEURL, SHOWS_WATCH_REGION, SHOWS_WATCH_PROVIDERS } from "../../api/api-settings";

import MainArchive from "../../components/MainArchive/MainArchive";

const OnAir = () => {
    
    const queryDate = (new Date()).toISOString().split('T')[0];
    const queryParams = SHOWS_WATCH_REGION+SHOWS_WATCH_PROVIDERS+'&sort_by=first_air_date.desc&air_date.lte='+queryDate+'&first_air_date.lte='+queryDate;

    return <MainArchive 
        displaysShows={true} 
        screenTitle="IN ONDA" 
        baseUrl={DISCOVER_SHOWS_BASEURL}
        queryParams={queryParams}/>
};

export default OnAir;