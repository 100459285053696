export const API_KEY = '?api_key=39fde29e053c47325f9911354e4ca533';
export const LANGUAGE = '&language=it-IT';
export const REGION = '&region=IT';

export const SHOWS_WATCH_REGION = '&watch_region=IT';
export const SHOWS_WATCH_PROVIDERS = '&with_watch_providers=8|119|9|337|39|359|350|40|109|110';

export const DISCOVER_MOVIES_BASEURL = 'https://api.themoviedb.org/3/discover/movie'+API_KEY+LANGUAGE+REGION;
export const DISCOVER_SHOWS_BASEURL = 'https://api.themoviedb.org/3/discover/tv'+API_KEY+LANGUAGE;

export const POSTER_BASEURL = 'http://image.tmdb.org/t/p/w500';
export const IMAGE_BASEURL = 'http://image.tmdb.org/t/p/original';

export const MOVIE_GENRES_BASEURL = 'https://api.themoviedb.org/3/genre/movie/list';
export const SHOW_GENRES_BASEURL = 'https://api.themoviedb.org/3/genre/tv/list';

export const GET_MOVIE_DETAILS_BASEURL = 'https://api.themoviedb.org/3/movie/';
export const GET_SHOW_DETAILS_BASEURL = 'https://api.themoviedb.org/3/tv/';

export const GET_MOVIE_PROVIDERS_BASEURL = (id) => 'https://api.themoviedb.org/3/movie/'+id+'/watch/providers'+API_KEY; 
export const GET_SHOW_PROVIDERS_BASEURL = (id) => 'https://api.themoviedb.org/3/tv/'+id+'/watch/providers'+API_KEY; 

export const GET_MOVIE_TRAILER_BASEURL = (id) => 'https://api.themoviedb.org/3/movie/'+id+'/videos'+API_KEY+LANGUAGE; 
export const GET_SHOW_TRAILER_BASEURL = (id) => 'https://api.themoviedb.org/3/tv/'+id+'/videos'+API_KEY+LANGUAGE; 

export const GET_MOVIE_CREDITS_BASEURL = (id) => 'https://api.themoviedb.org/3/movie/'+id+'/credits'+API_KEY+LANGUAGE; 
export const GET_SHOW_CREDITS_BASEURL = (id) => 'https://api.themoviedb.org/3/tv/'+id+'/credits'+API_KEY+LANGUAGE; 

export const GET_SIMILAR_MOVIES_BASEURL = (id) => 'https://api.themoviedb.org/3/movie/'+id+'/similar'+API_KEY+LANGUAGE; 
export const GET_SIMILAR_SHOWS_BASEURL = (id) => 'https://api.themoviedb.org/3/tv/'+id+'/similar'+API_KEY+LANGUAGE; 

export const GET_NOW_PLAYING_MOVIES_HP = 'https://api.themoviedb.org/3/movie/now_playing'+API_KEY+LANGUAGE;
export const GET_NOW_PLAYING_SHOWS_HP = 'https://api.themoviedb.org/3/tv/on_the_air'+API_KEY+LANGUAGE;

export const GET_POPULAR_MOVIES_HP = 'https://api.themoviedb.org/3/movie/popular'+API_KEY+LANGUAGE;
export const GET_POPULAR_SHOWS_HP = 'https://api.themoviedb.org/3/tv/popular'+API_KEY+LANGUAGE;


export const GET_SEARCH_URL = (type, keywords) => 'https://api.themoviedb.org/3/search/'+
    type+API_KEY+LANGUAGE+'&query='+keywords;


export const GET_LATEST_MOVIE = 'https://api.themoviedb.org/3/movie/latest'+API_KEY+LANGUAGE;