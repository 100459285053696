import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
    genres: [],
    errorMessgae: ''
};

const shows = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SHOW_GENRES:
            return {
                ...state,
                genres: action.payload
            }
        default: 
            return state
    }
};

export default shows;