import ArchiveCard from "../../ArchiveCard/ArchiveCard";
import Slider from "react-slick";
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import { useState, useRef } from "react";

const HpCarousel = ({ items, displaysShows }) => {
    
    const carouselRef = useRef();
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const sliderSettings = {
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const carouselPrev = () => {
        carouselRef.current.slickPrev();
    };

    const carouselNext = () => {
        carouselRef.current.slickNext();
    };
    
    return (
        <div className="hp-carousel">
            <div 
                style={{cursor: isDragging ? 'grab' : ''}}
                onMouseDown={()=> {
                    setIsMouseDown(true);
                }}
                onMouseMove={()=> {
                    if(isMouseDown && !isDragging) {
                        setIsDragging(true);
                    } 
                    if(!isMouseDown && isDragging) {
                        setIsDragging(false);
                    }
                }}
                onMouseUp={()=> {
                    setIsMouseDown(false);
                }}
                onMouseLeave={()=>{
                    setIsMouseDown(false);
                    setIsDragging(false);
                }}
            >
                <Slider {...sliderSettings} ref={carouselRef}>
                    {items.map((itm)=>{
                        
                        const url = displaysShows ? '/serie-tv/' : '/film/';
                        const additionalClass = displaysShows ? ['show', 'slider-card'] : 'slider-card';
                        
                        return (
                            <div key={itm.id}>
                                <ArchiveCard 
                                    id={itm.id}
                                    key={itm.id} 
                                    title={displaysShows ? itm.name : itm.title} 
                                    releaseDate={displaysShows ? itm.first_air_date : itm.release_date} 
                                    posterPath={itm.poster_path} 
                                    vote={itm.vote_average}
                                    url={url}
                                    additionalClass={additionalClass}
                                    isDragging={isDragging}/>
                            </div>
                        )
                    })}
                </Slider>
            </div>
            <div className="slider-custom-controls">
                <button className="custom-slider-button" onClick={carouselPrev}>
                    <AiOutlineLeft />
                </button>
                <button className="custom-slider-button" onClick={carouselNext}>
                    <AiOutlineRight />
                </button>
            </div>
        </div>
    );
};

export default HpCarousel;