import { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './MainHeader.module.css';

import Logo from '../Logo/Logo';
import Submenu from './Submenu/Submenu';

import { VscMenu, VscArrowRight} from 'react-icons/vsc';

const MainHeader = ({handleNavDisplay}) => {

    const [activeSubmenu, setActiveSubmenu] = useState();

    const SubmenuList = [
        {
            title: 'FILM',
            subMenuLinks: [
                {
                    title: 'IN SALA',
                    path: '/film/in-sala'
                },
                {
                    title: 'ULTIME USCITE',
                    path: '/film/ultime-uscite'
                },
                {
                    title: 'POPOLARI',
                    path: '/film/popolari'
                },
                {
                    title: 'TOP RATED',
                    path: '/film/top-rated'
                },
                {
                    title: 'COMING SOON',
                    path: '/film/coming-soon'
                }
            ]
        },
        {
            title: 'SERIE TV',
            subMenuLinks: [
                {
                    title: 'IN ONDA',
                    path: '/serie-tv/in-onda'
                },
                {
                    title: 'POPOLARI',
                    path: '/serie-tv/popolari'
                },
                {
                    title: 'TOP RATED',
                    path: '/serie-tv/top-rated'
                }
            ]
        }
    ];

    const handleSubmenu = (index) => {
        setActiveSubmenu((prevIndex)=>{
           return prevIndex === index ? null : index;
        });
    };

    return (
        <Fragment>
            <div className={classes["main-header"]}>
                <Logo />
                <div className={classes.hamburger} onClick={handleNavDisplay}>
                    <VscMenu />
                </div>
            </div>    
           
            <nav>
                <div className={classes.close} onClick={handleNavDisplay}>
                    <VscArrowRight />
                </div>
                <ul className={classes.menu}>
                    <li>
                        <NavLink to="/" className={(navData) => navData.isActive ? classes['active-link'] : null}>HOMEPAGE</NavLink>
                    </li>
                    {SubmenuList.map((subMn, index) => {
                        return (
                            <Submenu key={index} index={index} title={subMn.title} links={subMn.subMenuLinks} active={activeSubmenu} clicked={handleSubmenu}/>
                        )
                    })}
                    <li>
                        <NavLink to="/attori" className={(navData) => navData.isActive ? classes['active-link'] : null}>ATTORI</NavLink>
                    </li>
                </ul>
            </nav>
            
        </Fragment>
       
        
    );
};

export default MainHeader;

