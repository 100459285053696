import { Link } from 'react-router-dom';
import { POSTER_BASEURL } from '../../api/api-settings';

import scoreCalc from '../../utility/scoreCalc';
import dateFormatted from '../../utility/dateFormatter';

import classes from './ArchiveCard.module.css';


const ArchiveCard = ({id, title, releaseDate, posterPath, vote, url, additionalClass, isDragging}) => {
    
    let releaseDateFormatted = '';

    let cardClasses;
    

    if(releaseDate) {
        releaseDateFormatted = dateFormatted(releaseDate);
    }

    if(isDragging) {
        cardClasses = classes['archive-card']+' '+classes['dragging'];
        
    } else {
        cardClasses = classes['archive-card'];
    }

    if(additionalClass) {
        if(Array.isArray(additionalClass) && additionalClass.length > 0) {
            additionalClass.map(c => cardClasses+= ' '+classes[c]);
        } else {
            cardClasses += ' '+classes[additionalClass];
        }
    }

    let score = scoreCalc(vote);
    
        
    return (
        <Link to={url+id} className={cardClasses}>
            {posterPath && 
                <div 
                    className={classes['archive-card__poster']} 
                    style={{backgroundImage: 'url(' + POSTER_BASEURL+posterPath + ')'}}
                    draggable={false}>
                </div>
            }
            <div className={classes['archive-card__content']} >
                <h3>{title}</h3>
                {releaseDate && <span>{releaseDateFormatted}</span>}
                <div className={classes['archive-card__score']}>
                    {score}
                </div>
            </div>
        </Link>
    );
};

export default ArchiveCard;