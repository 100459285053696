import React, { useEffect, useReducer } from 'react';
import { GET_LATEST_MOVIE, GET_MOVIE_DETAILS_BASEURL, API_KEY, LANGUAGE } from '../../../api/api-settings';
import ArchiveCard from '../../ArchiveCard/ArchiveCard';
import MainContainer from '../..//Layout/MainContainer';
import Loader from '../..//Layout/Loader';
import { Transition } from 'react-transition-group';

const rouletteReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LATEST_ID':
            return {
                ...state,
                latestId: action.payload
            }
            break;
        case 'SET_IS_LOADING':
            return {
                ...state,
                isLoading: action.payload
            }
            break;
        case 'SET_IS_LOADING_RANDOM_ITEM':
            return {
                ...state,
                isLoadingRandomItem: action.payload
            }
            break;
        case 'SET_RANDOM_ITEM': 
            return {
                ...state,
                randomItem: action.payload
            }
            break;
        case 'SET_ERROR': 
            return {
                ...state,
                error: action.payload
            }
            break;
        default: 
            return state;
    }
}

const WatchRoulette = () => {

    const [state, dispatch] = useReducer(rouletteReducer, {
        randomItem: null,
        isLoading: false,
        isLoadingRandomItem: false,
        latestId: null,
        error: ''
    });

    const errorMsg = 'Servizio non disponibile al momento prova più tardi';

    useEffect(() => {

        (async () => {
            try {
                dispatch({type: 'SET_IS_LOADING', payload: true });
                const response = await fetch(GET_LATEST_MOVIE);
                if(response.ok) {
                    const latestItem = await response.json();
                    dispatch({type: 'SET_IS_LOADING', payload: false });
                    if(latestItem.id) {
                        dispatch({type: 'SET_LATEST_ID', payload: latestItem.id });
                    }
                } else {
                    throw new Error(errorMsg);
                }

            } catch (error) {
                dispatch({type: 'SET_ERROR', payload: error});
                dispatch({type: 'SET_IS_LOADING', payload: false });
            }
        })();

    }, []);

    const fetchRandomItem = async () => {
        try {
            dispatch({type: 'SET_IS_LOADING_RANDOM_ITEM', payload: true});
            const randomId =  Math.floor(Math.random() * (state.latestId - 1) + 1);
            let i = 0;
            let randomItem;
            
            while(i <= 3) {
                const response = await fetch(GET_MOVIE_DETAILS_BASEURL+randomId+API_KEY+LANGUAGE);
                if(response.status === 200) {
                    randomItem = await response.json();
                    break;
                }
                i++;
            };

            if(Object.keys(randomItem).length > 0) {
                setTimeout(() => {
                    dispatch({type: 'SET_IS_LOADING_RANDOM_ITEM', payload: false});
                    dispatch({type: 'SET_RANDOM_ITEM', payload: randomItem});
                }, 1600);
            } else {
                throw new Error(errorMsg);
            }

        } catch (error) {
            dispatch({type: 'SET_ERROR', payload: error});
            dispatch({type: 'SET_IS_LOADING_RANDOM_ITEM', payload: false });
        }
    };

    const duration = 300;

    return (
        <div className="watch-roulette">
            <MainContainer>
                {state.isLoading && <Loader />}

                {!state.isLoading && state.latestId ?
                    <div className="watch-roulette__wrapper">
                        
                        <div className="watch-roulette-col">
                            <h3>Non sai che film guardare, prova a pescarne uno casualmente!</h3>
                            <button onClick={fetchRandomItem}>SPIN</button>
                        </div>  

                        <div className="watch-roulette-col">
                            <div class="roulette">
                                <div class={"roulette-container"+(state.isLoadingRandomItem ? " spin" : "")}>
                                    <div class="row">
                                        <div class="card"></div>
                                        <div class="card"></div>
                                        <div class="card"></div>
                                    </div>
                                    <div class="row ">
                                        <div class="card"></div>
                                        <div class="card"></div>
                                        <div class="card"></div>
                                    </div>
                                    <Transition in={!state.isLoadingRandomItem && state.randomItem} timeout={duration} mountOnEnter={true}>
                                        { transitionState => (
                                            <div className={`fade fade-${transitionState}`}>
                                                <ArchiveCard 
                                                    id={state.randomItem.id}
                                                    key={state.randomItem.id} 
                                                    title={false ? state.randomItem.name : state.randomItem.title} 
                                                    releaseDate={false ? state.randomItem.first_air_date : state.randomItem.release_date} 
                                                    posterPath={state.randomItem.poster_path} 
                                                    vote={state.randomItem.vote_average}
                                                    url={'/film/'}
                                                    additionalClass={"roulette-card"}
                                                />
                                            </div>
                                        )}
                                    </Transition>     
                                </div>
                            </div>
                        </div>

                    </div> 
                    
                    :

                    !state.isLoading && errorMsg
                }
            </MainContainer>
        </div>
    )

};

export default WatchRoulette;