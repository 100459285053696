import { DISCOVER_MOVIES_BASEURL } from "../../api/api-settings";

import MainArchive from "../../components/MainArchive/MainArchive";

const Popular = () => {
    
    const queryParams = '&with_release_type=3|2&sort_by=popularity.desc';

    return <MainArchive 
        displaysShows={false} 
        screenTitle="POPOLARI" 
        baseUrl={DISCOVER_MOVIES_BASEURL}
        queryParams={queryParams}/>
};

export default Popular;