import React, { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { BsSearch } from "react-icons/bs";

const SearchBar = ({ displaysShows }) => {
    
    const location = useLocation();
    const { cat } = useParams();

    let searchQuery = location.search;
    let searchParams = new URLSearchParams(searchQuery);

    const searchInputRef = useRef();
    const [searchCat, setSearchCat] = useState(!displaysShows ? 'film' : 'shows');
    
    const navigate = useNavigate();

    const handleSerchSubmit = (cat) => {
       navigate('/search/'+cat+'/?keywords='+searchInputRef.current.value);
    }

    useEffect(() => {
        cat && setSearchCat(cat);
    }, [cat])

    return (
        <Fragment>
            <form className="searchbar" onSubmit={(e) => {
                e.preventDefault();
                searchInputRef.current.value && 
                    handleSerchSubmit(searchCat);
            }}>
                <input type="text" name="search" ref={searchInputRef} defaultValue={searchParams.get('keywords')} />
                <button type="submit">
                    <BsSearch />
                </button>  
            </form>
            <div className={'film-tv__switch ' +searchCat }>
                <span onClick={() => {
                    if(searchInputRef.current.value && cat) {
                        handleSerchSubmit('film');
                    } else {
                        setSearchCat('film');
                    }
                }}>FILM</span>
                <span onClick={() => {
                    if(searchInputRef.current.value && cat) {
                        handleSerchSubmit('shows');
                    } else {
                        setSearchCat('shows');
                    }
                }}>SERIE TV</span>
            </div>
        </Fragment>
    )
}

SearchBar.defaultProps = {
    displaysShows: false
};

export default SearchBar;