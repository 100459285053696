import { DISCOVER_MOVIES_BASEURL } from "../../api/api-settings";

import MainArchive from "../../components/MainArchive/MainArchive";

const NowPlaying = () => {

    const startDate = new Date();
    
    let endDate = new Date();
    endDate = endDate.setDate(endDate.getDate() - 30)
    endDate = new Date(endDate);

    const queryParams = '&release_date.lte='+startDate.toISOString()+'&release_date.gte='+endDate.toISOString()
        +'&with_release_type=3|2&sort_by=release_date.desc';


    return <MainArchive 
        displaysShows={false} 
        screenTitle="IN SALA" 
        baseUrl={DISCOVER_MOVIES_BASEURL}
        queryParams={queryParams}/>
};

export default NowPlaying;