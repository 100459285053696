import classes from './ActorCard.module.css';
import { POSTER_BASEURL } from '../../api/api-settings';

const ActorCard = ({name, character, profilePic}) => {
    return (
        <div className={classes['actor-card']}>
            <div className={classes['actor-card__pic']} style={{backgroundImage: 'url('+POSTER_BASEURL+profilePic+')'}}></div>
            <div className={classes['actor-card__content']}>
                <h3 className={classes['actor-card__name']}>{name}</h3>   
                <h4 className={classes['actor-card__character']}>{character}</h4>           
            </div>
        </div>
    );
};

export default ActorCard;