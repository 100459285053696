import { SHOW_GENRES_BASEURL, API_KEY, LANGUAGE } from '../../api/api-settings';

import * as actionTypes from './actionTypes';


export const fetchShowGenres = () => {
    return async dispatch => {
        try {
            const response = await fetch(SHOW_GENRES_BASEURL+API_KEY+LANGUAGE);
            if(!response.ok) {
                throw new Error('Errore nel recuperare il genere delle serie tv in catalogo');
            }
            const data = await response.json();
            dispatch({type: actionTypes.FETCH_SHOW_GENRES, payload: data.genres });
        } catch(error) {
            console.log(error);
        }
    };
}