import { Fragment, useEffect, useState } from 'react';
import { fetchTMDB } from '../../api/fetch-tmdb';
import './ArchiveSingle.css';

const Video = ({ reqUrl }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [trailer, setTrailer] = useState();
    const [error, setError] = useState('');

    useEffect(() => {

        const fetchTrailer = async () => {
            
            setIsLoading(true);

            const response = await fetchTMDB(reqUrl);
            let video = []; 

            if(response.data) {
                if(response.data && response.data.results.length > 0) {
                    video = response.data.results.filter(v => v.type === 'Trailer' && v.official);
                }
                if(video.length > 0) {
                    const trailer = 'https://www.youtube.com/embed/'+video[0].key;
                    setIsLoading(false);
                    setTrailer(trailer);
                }
            }
            if(response.error) {
                setIsLoading(false);
                setError('Non è stato possibile recuperare il trailer');
            }
        }

        fetchTrailer(reqUrl);

    }, [reqUrl]);

    return (
        <div className="trailer">
            {!isLoading && error === '' && trailer && 
                <Fragment>
                    <h3>Trailer</h3>
                    <div className="trailer__wrapper">
                        <iframe title="trailer" src={trailer} frameBorder="0" allowFullScreen></iframe>
                    </div>
                </Fragment>
            }
            {!isLoading && error !== '' && <p>{error}</p>}
        </div>
    );
}

export default Video;