import * as actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
    nowPlayingMovies : [],
    nowPlayingShows : [],
    popularMovies: [],
    popularShows: []
};

const homepage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.FETCH_NOW_PLAYING_MOVIES:
            return {
                ...state,
                nowPlayingMovies: action.payload
            }
        case actionTypes.FETCH_NOW_PLAYING_SHOWS:
            return {
                ...state,
                nowPlayingShows: action.payload
            }
        case actionTypes.FETCH_POPULAR_MOVIES:
            return {
                ...state,
                popularMovies: action.payload
            }
        case actionTypes.FETCH_POPULAR_SHOWS:
            return {
                ...state,
                popularShows: action.payload
            }
        default: 
            return state;
    } 
}

export default homepage;