import React, { Component } from 'react';
import MainContainer from '../components/Layout/MainContainer';

import NowPlaying from '../components/Homepage/NowPlaying/NowPlaying';
import Popular from '../components/Homepage/Popular/Popular';
import Head from '../components/Homepage/Head/head';
import WatchRoulette from '../components/Homepage/WatchRoulette/WatchRoulette';
class Homepage extends Component {

    render() {
        return (
            <React.Fragment>
                <Head />
                <MainContainer>
                    <NowPlaying />
                    <Popular/>
                </MainContainer>
                <WatchRoulette />
            </React.Fragment>
        )
    }
}

export default Homepage;