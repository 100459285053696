import { useEffect, useState } from 'react';

import { IMAGE_BASEURL } from '../../api/api-settings';
import { fetchTMDB } from '../../api/fetch-tmdb';

import './ArchiveSingle.css';

const Providers = ({ reqUrl }) => {
    
    const [providers, setProviders] = useState();
    const [error, setError] = useState('');

    useEffect(()=>{
        const fetchProviders = async () => {
            
            const response = await fetchTMDB(reqUrl);
            if(response.data) {
                if(response.data.results.IT) {
                    setProviders(response.data.results.IT);
                }
            }
            if(response.error) {
                setError('Non è stato possibile recuperare i provider disponibili');
            }
        }

        fetchProviders();
    }, [reqUrl]);

    let buyProviders = [], streamProviders = [], rentProviders = [];

    if(providers) {
        
        const { buy } = providers;
        const { flatrate:stream } = providers;
        const { rent } = providers;
                
        let i = 0;

        if(buy && buy.length > 0) {
            buyProviders = buy.map((provider) =>{
                return (
                    <img key={(i++)+provider.provider_name} src={IMAGE_BASEURL+provider.logo_path} alt={provider.provider_name}></img>
                )
            });
        }

        if(stream && stream.length > 0) {
            streamProviders = stream.map((provider) =>{
                return (
                    <img key={(i++)+provider.provider_name} src={IMAGE_BASEURL+provider.logo_path} alt={provider.provider_name}></img>
                )
            });
        }

        if(rent && rent.length > 0) {
            rentProviders = rent.map((provider) =>{
                return (
                    <img key={(i++)+provider.provider_name} src={IMAGE_BASEURL+provider.logo_path} alt={provider.provider_name}></img>
                )
            });
        }
        
    }


    return (
        <div className="providers">
            {!error && buyProviders.length > 0 && 
                <div>
                    <h3>ACQUISTABILE</h3> 
                    {buyProviders}
                </div>
            }
            {!error && streamProviders.length > 0 && 
                <div>
                    <h3>IN STREAMING</h3> 
                    {streamProviders}
                </div>
            }
            {!error && rentProviders.length > 0 && 
                <div>
                    <h3>NOLEGGIABILE</h3> 
                    {rentProviders}
                </div>
            }
            {!error && (rentProviders.length > 0 || streamProviders.length > 0 || buyProviders.length > 0) &&
                <p className="disclaimer">Le informazioni riguardo i provider sono gentilmente fornite da JustWatch</p>
            }
            {error && <p>{error}</p>}
        </div>
    );
};

export default Providers;