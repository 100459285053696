import ArchiveCard from "../../ArchiveCard/ArchiveCard";

import classes from "./ArchiveItemsList.module.css";

const ArchiveList = ({ displaysShows, items }) => {

    const url = displaysShows ? '/serie-tv/' : '/film/';
    const additionalClass = displaysShows ? 'show' : '';

    return (
        <div className={classes['archive__items-list']}>
        {
            items.map((itm)=>{
                return <ArchiveCard 
                    id={itm.id}
                    key={itm.id} 
                    title={displaysShows ? itm.name : itm.title} 
                    releaseDate={displaysShows ? itm.first_air_date : itm.release_date} 
                    posterPath={itm.poster_path} 
                    vote={itm.vote_average}
                    url={url}
                    additionalClass={additionalClass}
                />
            })
        }
        </div>
    );
};

export default ArchiveList;