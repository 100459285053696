export const fetchTMDB = async (baseUrl, queryString = "") => {
    try {
        const response = await fetch(baseUrl+queryString,{
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if(!response.ok) {
            throw new Error('Si è verificato un errore nel recuperare i dati... Riprova');
        }
        
        const data = await response.json();
        return {
            data
        };

    } catch(error) {
        return {
            error: error.message
        };
    }
};
