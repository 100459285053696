import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import movies from './reducers/movies';
import shows from './reducers/shows';
import homepage from './reducers/homepage';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({movies, shows, homepage});

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export default store;