import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Loader from '../../Layout/Loader';
import HpCarousel from "../HpCarousel/HpCarousel";

import * as actions from '../../../store/actions';

import '../Homepage.css';

const Popular = () => {

    const [displaysShows, setDisplaysShows] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const popularMovies = useSelector(state => state.homepage.popularMovies);
    const popularShows = useSelector(state => state.homepage.popularShows);

    useEffect(() => {

        const fetchNowPlaying = async () => {
            if(!displaysShows && popularMovies.length <= 0) {
                setIsLoading(true);
                await dispatch(actions.fetchPopularMovies());
                setIsLoading(false);
            }
    
            if(displaysShows && popularShows.length <= 0) {
                setIsLoading(true);
                await dispatch(actions.fetchPopularShows());
                setIsLoading(false);
            }
        };

        fetchNowPlaying();
        

    }, [popularMovies, popularShows, displaysShows, dispatch]);

    return (
        <div className="hp-carousel-section">
            <div className="hp-carousel-section__head">
                <h2>POPOLARI</h2>
                <div className={'film-tv__switch' +(displaysShows ? ' shows' : '') }>
                    <span onClick={() => {setDisplaysShows(false)}}>FILM</span>
                    <span onClick={() => {setDisplaysShows(true)}}>SERIE TV</span>
                </div>
            </div>
            {!isLoading && (popularMovies.length > 0 || popularShows.length > 0) &&
                <HpCarousel items={!displaysShows ? popularMovies : popularShows} displaysShows={displaysShows} />    
            }
            {isLoading && <Loader />}
        </div>
        
    )
};

export default Popular;