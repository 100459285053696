import React, { Fragment } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GET_SEARCH_URL } from '../../api/api-settings';
import SearchTab from './SearchTab';


const Search = () => { 

    const { cat } = useParams();
    const location = useLocation();

    let searchQuery = location.search;
    let searchParams = new URLSearchParams(searchQuery);
    let searchTerm = encodeURIComponent(searchParams.get('keywords'));

    let searchCat = cat === 'film' ? 'movie' : 'tv';

    const searchUrl = GET_SEARCH_URL(searchCat, searchTerm);

    return (
        <Fragment>
            <div className="tabs-wrapper">
                {cat === 'film' && 
                    <SearchTab title="FILM" searchUrl={searchUrl} />
                }
                {cat === 'shows' && 
                    <SearchTab title="SERIE TV" searchUrl={searchUrl} displaysShows={true}/>
                }
            </div>
        </Fragment>
    );
} 

export default Search;