import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Loader from '../../Layout/Loader';
import HpCarousel from "../HpCarousel/HpCarousel";

import * as actions from '../../../store/actions';

import '../Homepage.css';

const NowPlaying = () => {

    const [displaysShows, setDisplaysShows] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const nowPlayingMovies = useSelector(state => state.homepage.nowPlayingMovies);
    const nowPlayingShows = useSelector(state => state.homepage.nowPlayingShows);

    useEffect(() => {

        const fetchNowPlaying = async () => {
            if(!displaysShows && nowPlayingMovies.length <= 0) {
                setIsLoading(true);
                await dispatch(actions.fetchNowPlayngMovies());
                setIsLoading(false);
            }
    
            if(displaysShows && nowPlayingShows.length <= 0) {
                setIsLoading(true);
                await dispatch(actions.fetchNowPlayngShows());
                setIsLoading(false);
            }
        };

        fetchNowPlaying();
        

    }, [nowPlayingMovies, nowPlayingShows, displaysShows, dispatch]);

    return (
        <div className="hp-carousel-section">
            <div className="hp-carousel-section__head">
                <h2>ULTIME USCITE</h2>
                <div className={'film-tv__switch' +(displaysShows ? ' shows' : '') }>
                    <span onClick={() => {setDisplaysShows(false)}}>FILM</span>
                    <span onClick={() => {setDisplaysShows(true)}}>SERIE TV</span>
                </div>
            </div>
            {!isLoading && (nowPlayingMovies.length > 0 || nowPlayingMovies.length > 0) &&
                <HpCarousel items={!displaysShows ? nowPlayingMovies : nowPlayingShows} displaysShows={displaysShows} />    
            }
            {isLoading && <Loader />}
        </div>
        
    )
};

export default NowPlaying;