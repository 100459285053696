import { DISCOVER_MOVIES_BASEURL } from "../../api/api-settings";

import MainArchive from "../../components/MainArchive/MainArchive";



const ComingSoon = () => {

    const queryParams = '&release_date.gte='+(new Date()).toISOString()+'&with_release_type=3|2&sort_by=release_date.asc';
    
    return <MainArchive
        displaysShows={false} 
        screenTitle="COMING SOON" 
        baseUrl={DISCOVER_MOVIES_BASEURL}
        queryParams={queryParams}/>
};

export default ComingSoon;