import React, { Component, Fragment } from 'react';
import { fetchTMDB } from '../../api/fetch-tmdb';

import Loader from '../Layout/Loader';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import ArchiveCard from '../ArchiveCard/ArchiveCard';

import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

import './ArchiveSingle.css';

class Related extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            related: [],
            error: '',
            hideControls: false,
            isDragging: false,
            isMouseDown: false
        };
        this.sliderSettings = {
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                }
            ]
        }
        this.carouselPrev = this.carouselPrev.bind(this);
        this.carouselNext = this.carouselNext.bind(this);
    }   

    fetchRelated = async (reqUrl) =>  {
        const response = await fetchTMDB(reqUrl);
        if(response.data) {
            this.setState({isLoading: false});
            this.setState({related: response.data.results}); 
            if(this.slider) {
                this.slider.slickGoTo(0, true);  
            }
        }
        if(response.error) {
            this.setState({error: 'Non è stato possibile recuperare i correlati', isLoading: false});   
        }
    }

    componentDidMount() {
        this.fetchRelated(this.props.reqUrl);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.reqUrl !== this.props.reqUrl) {
            this.fetchRelated(this.props.reqUrl);
        }
    }

    carouselPrev () {
        this.slider.slickPrev();
    }

    carouselNext () {
        this.slider.slickNext();
    }

    render () {
        return (
            <div className={`related ${this.state.isLoading ? 'loading' : ''}`}>
                {this.state.isLoading && <Loader />}
                {this.state.related && this.state.related.length > 0 && !this.state.isLoading &&
                    <Fragment>
                        <h3>CHI HA VISTO {this.props.title} HA VISTO ANCHE...</h3>
                        <div 
                            style={{cursor: this.state.isDragging ? 'grab' : ''}}
                            onMouseDown={()=> {
                                this.setState({
                                    isMouseDown: true
                                });
                            }}
                            onMouseMove={()=> {
                                if(this.state.isMouseDown && !this.state.isDragging) {
                                    this.setState({
                                        isDragging: true
                                    });
                                } 
                                if(!this.state.isMouseDown && this.state.isDragging) {
                                    this.setState({
                                        isDragging: false
                                    });
                                }
                            }}
                            onMouseUp={()=> {
                                this.setState({
                                    isMouseDown: false
                                });
                            }}
                            onMouseLeave={()=>{
                                this.setState({
                                    isMouseDown: false,
                                    isDragging: false
                                });
                            }}
                        >
                            <Slider {...this.sliderSettings} ref={c => (this.slider = c)}>
                                {this.state.related.map((itm)=>{
                                    
                                    const url = this.props.displaysShows ? '/serie-tv/' : '/film/';
                                    const additionalClass = this.props.displaysShows ? ['show', 'slider-card'] : 'slider-card';
                                    
                                    return (
                                        <div key={itm.id}>
                                            <ArchiveCard 
                                                id={itm.id}
                                                key={itm.id} 
                                                title={this.props.displaysShows ? itm.name : itm.title} 
                                                releaseDate={this.props.displaysShows ? itm.first_air_date : itm.release_date} 
                                                posterPath={itm.poster_path} 
                                                vote={itm.vote_average}
                                                url={url}
                                                additionalClass={additionalClass}
                                                isDragging={this.state.isDragging}/>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                        <div className="slider-custom-controls">
                            <button className="custom-slider-button" onClick={this.carouselPrev}>
                                <AiOutlineLeft />
                            </button>
                            <button className="custom-slider-button" onClick={this.carouselNext}>
                                <AiOutlineRight />
                            </button>
                        </div>
                    </Fragment>
                }
                {this.state.error && !this.state.isLoading && <p>{this.state.error}</p> }
            </div>
        )
    }
}

export default Related;