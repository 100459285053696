import { useState } from 'react';
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';

import classes from '../ArchiveFilter.module.css';

const RatingSelector = ({ displaysShows }) => {

    const INITIAL_RATING = [
        <BsStar key={1} />,
        <BsStar key={2} />,
        <BsStar key={3} />,
        <BsStar key={4} />,
        <BsStar key={5} />
    ];

    const [rating, setRating] = useState([...INITIAL_RATING]);

    const ratingDisplayerHandler = (e) => {
        const vote = e.target.value;
        let starsNumber = (vote*5)/10;
        if(starsNumber === 0) {
            setRating([...INITIAL_RATING]);
        } else {
            let i = 0;
            let score =  [...INITIAL_RATING];
            while(i+1<=starsNumber) {
                score.splice(i, 1, <BsStarFill key={i}/>);
                i++;
            }
            if(starsNumber - i > 0.5) {
                score.splice(i, 1, <BsStarHalf key={i+1}/>)
            }
            setRating(score);
        }
    }

    return (
        <div className={classes['rating-selector']}>
            <h4>Rating</h4>
            <input 
                type="range" 
                name="rating" 
                defaultValue="0" 
                min="0" 
                max="10" 
                step="0.5" 
                onChange={ratingDisplayerHandler}>
            </input>
            <div className={!displaysShows ? classes['rating-selector__rating-displayer'] : classes['rating-selector__rating-displayer']+' '+classes['tv-shows'] }>
                {rating}
            </div>
        </div>
    );
};

export default RatingSelector;