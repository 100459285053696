const Loader = () => {
    return (
        <div className="loader">
            <span></span>
            <span></span>
            <span></span>
        </div>    
    );
}

export default Loader;