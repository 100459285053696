import './App.css';

import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import MainHeader from './components/MainHeader/MainHeader';
import OffCanvasWrapper from './components/Layout/OffCanvasWrapper';

import Homepage from './screens/Homepage';
import NowPlaying from './screens/Movies/NowPlaying';
import LatestMovies from './screens/Movies/LatestMovies';
import Popular from './screens/Movies/Popular';
import TopRated from './screens/Movies/TopRated';
import ComingSoon from './screens/Movies/ComingSoon';

import OnAir from './screens/Shows/OnAir';
import PopularShows from './screens/Shows/Popular';
import TopRatedShows from './screens/Shows/TopRated';

import Movie from './screens/Movies/Movie';
import Show from './screens/Shows/Show';
import Search from './components/Search/Search';

function App() {

	const [displayNav, setDisplayNav] = useState(false);
	
	const location = useLocation();

	const handleNavDisplay = () => {
		
		if(document.documentElement.classList.contains("no-scroll")) {
			setTimeout(function() {
				document.documentElement.classList.remove("no-scroll");
			}, 400);
		} else {
			document.documentElement.classList.add("no-scroll");
		}
		setDisplayNav(prevState => !prevState);

	};

	useEffect(() => {
		if(document.documentElement.classList.contains("no-scroll")) {
			setTimeout(function() {
				document.documentElement.classList.remove("no-scroll");
			}, 400);
		}
		setDisplayNav(false);
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<div className="App">
			<OffCanvasWrapper  displayNav={displayNav} handleNavDisplay={handleNavDisplay}>
				<MainHeader displayNav={displayNav} handleNavDisplay={handleNavDisplay}/>
				<Routes>
					<Route path="/" element={<Homepage/>} />
					<Route path="/film/in-sala" element={<NowPlaying/>}  />
					<Route path="/film/ultime-uscite" element={<LatestMovies/>}  />
					<Route path="/film/popolari" element={<Popular/>}  />
					<Route path="/film/top-rated" element={<TopRated/>}  />
					<Route path="/film/coming-soon" element={<ComingSoon/>}  />
					<Route path="/film/:id" element={<Movie />}  />
					<Route path="/serie-tv/in-onda" element={<OnAir/>}  />
					<Route path="/serie-tv/popolari" element={<PopularShows/>}  />
					<Route path="/serie-tv/top-rated" element={<TopRatedShows/>}  />
					<Route path="/serie-tv/:id" element={<Show />}  />
					<Route path="/search/:cat/" element={<Search />} />
				</Routes>
			</OffCanvasWrapper>
		</div>
	);
}

export default App;
