import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import classes from './ArchiveFilter.module.css'; 

import { MdClose, MdOutlineFilterAlt } from 'react-icons/md';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import DatePicker from './DatePicker/DatePicker';

import RatingSelector from './RatingSelector/RatingSelector';

class ArchiveFilter extends Component {
    
    constructor(props) {
        super(props);  
        this.formRef = React.createRef();
        this.onSubmitFilterForm = this.onSubmitFilterForm.bind(this);  
        this.openFilterHandler = this.openFilterHandler.bind(this);
        this.accordionHandler = this.accordionHandler.bind(this);
        this.state = {
            filterOpen: false,
            activeAccordion: []
        }
    }

    componentDidMount() {
        if(this.props.genres.length < 1) {
            this.props.fetchGenres();
        }
    }

    accordionHandler(accordionId) {
        this.setState((prevState)=>{
            let updatedActiveAccordion = [...prevState.activeAccordion];
            if(updatedActiveAccordion.includes(accordionId)) {
                updatedActiveAccordion = updatedActiveAccordion.filter(el => el !== accordionId);
                return {
                    activeAccordion: updatedActiveAccordion
                }
            } else {
                return {
                    activeAccordion: [
                       ...prevState.activeAccordion,
                       accordionId
                    ]
                }
            }
        });
    }

    openFilterHandler() {
        this.setState(prevState => {
            return {
                filterOpen: !prevState.filterOpen
    
            }
        });
    }

    onSubmitFilterForm(e) {
        e.preventDefault();
        const filterData = new FormData(this.formRef.current);
        this.setState({filterOpen: false});
        this.props.onFilterSubmit(filterData);
    }

    render () {
        
        let genres = [];
        let archiveClasses = !this.state.filterOpen ? classes['archive-filter'] : classes['archive-filter']+' '+classes['open']; 
        
        if(this.props.genres.length > 0) {
            genres = this.props.genres.map((el) => {
                return (
                    <div className={classes['genre-tag']} key={el.id}>
                        <input id={el.id} type="checkbox" name="genres" value={el.id} />
                        <label htmlFor={el.id}>
                            {el.name}
                        </label>
                    </div>
                    
                );
            });
        }

        return (
            <div className={archiveClasses}>
                <div className={classes["archive-filter__head"]} onClick={this.openFilterHandler}>
                    <h3>Filtra</h3>
                    <MdOutlineFilterAlt />
                </div>
                <div className={classes["archive-filter__body"]}>
                    <span className={classes["archive-filter__body-close"]} onClick={this.openFilterHandler}>
                        <MdClose />
                    </span>
                    <form className={classes["archive-filter__form"]} onSubmit={this.onSubmitFilterForm} ref={this.formRef}> 
                        <div className={classes["archive-filter__form-section"]}>
                            <RatingSelector displaysShows={this.props.displaysShows} />
                        </div>
                        <div className={classes["archive-filter__form-section"]}>
                            <DatePicker />
                        </div>
                        <div className={
                            this.state.activeAccordion.includes('genresAccordion') ?
                            classes["archive-filter__form-section"]+' '+classes["open-accordion"] :
                            classes["archive-filter__form-section"]
                            }>
                            <div className={classes['form-section__head']} onClick={() => this.accordionHandler('genresAccordion')}>
                                <h4>Generi</h4>
                                {!this.state.activeAccordion.includes('genresAccordion') ? <AiOutlinePlus /> : <AiOutlineMinus />}
                            </div>
                            <div className={classes['form-section__body']}>
                                {genres.length > 0 ? genres : <span>Loading</span>}   
                            </div>    
                        </div>
                        <button type="submit">FILTRA</button>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        genres: ownProps.displaysShows ? state.shows.genres : state.movies.genres
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const fetchGenres = ownProps.displaysShows ? actions.fetchShowGenres : actions.fetchMovieGenres;
    return {
        fetchGenres: () => dispatch(fetchGenres())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveFilter);